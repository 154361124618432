@tailwind base;
@tailwind components;
@tailwind utilities;

.Grid {
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(
            0deg,
            transparent,
            transparent calc(var(--grid-size) - 1px),
            black calc(var(--grid-size) - 1px),
            black var(--grid-size)
        ),
        repeating-linear-gradient(
            -90deg,
            transparent,
            transparent calc(var(--grid-size) - 1px),
            black calc(var(--grid-size) - 1px),
            black var(--grid-size)
        );
    background-size: var(--grid-size) var(--grid-size);
    pointer-events: none;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror {
    position: static !important;
}

.reveal img {
    max-width: none;
    max-height: none;
    margin: 0;
}
